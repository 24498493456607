<template>
    <h1>
        Nasa Space App Challenge
    </h1>
    <p>
        Finnland ist für seinen grossen Kaffeekonsum bekannt. Umso erstaunlicher
        finde ich es während meines Aufenthaltes hier, dass der Kaffee vom Standard
        her sehr unterhalb dem des Schweizerischen ist. Auf dem Weg, eine Tasse
        dieses kulinarischen Abenteuers für 0.61€ zu holen, entdeckte ich ein
        Plakat mit einem wunderschönen Bild eines sterbenden Sterns drauf – ein
        Phänomen,
        <a href="https://www.youtube.com/watch?v=rPh3c8Sa37M">
            das uns die Existenz überhaupt ermöglicht
        </a>
        . Begleitet wird die visuelle Tragödie mit den ermutigenden Worten, beim
        Hackathon «Space Apps» der NASA mitzumachen. Gesagt getan!
    </p>
    <h2>
        Prolog
    </h2>
    <p>
        Der erste Tag startete mit einem herzlichen Frühstuck für die Teilnehmenden
        und dem klassischen Part mit den grundsätzlichen Informationen. Später ging
        es um die Teambildung und der Auswahl der Challenge. Wir haben uns bewusst
        für etwas entschieden, das uns komplett aus der Komfortzone holt und wir
        uns nicht sicher sind, ob wir überhaupt etwas erreichen werden können.
    </p>
    <p>
        Die Challenge besteht darin, mittels künstlicher Intelligenz die Bilder der
        NASA in einer kreativen Art und Weise zu verarbeiten, dass sie für die
        breite Masse interessant und zugänglich dargeboten werden kann. Dafür
        sollen bestehende Bilder der NASA über eine API geholt, verarbeitet und
        dargestellt werden. Im ersten Schritt soll einfach die Farbe des Bildes
        geändert werden können. Für die weiteren Schritte schauen wir dann, ob wir
        überhaupt soweit kommen und was mit ab dann mit dem bestehenden Projekt
        noch so machen können.
    </p>
    <h2>
        Der erste Tag
    </h2>
    <p>
        Mit frischem Tatendrang setzten wir uns an die Recherche und kamen schon
        mit der ersten Lösungsidee: Mithilfe von Python und den bekannten
        Bibliotheken für maschinelles Lernen den Text kategorisieren, damit wir auf
        dessen Basis die gewünschten Bilder von den NASA-API’s holen können. Die
        Bilder können wir dann bearbeiten und schlussendlich anzeigen lassen.
    </p>
    <p>
        Das Aufsetzen von Python und den dazugehörigen Bibliotheken verlief ohne
        Probleme. Allerdungs wusste ich zu diesem Zeitpunkt nicht, dass <a
            href="https://en.wikipedia.org/wiki/Natural_language_processing">NLP</a>
        mit Vektoren arbeitet. Das heisst, dass die verschiedenen Bedeutungen von
        Wörtern miteinander verrechnet werden und das Ergebnis dann ein Punkt in
        einem Vektorraum ist. Wie dieser Punkt klassifiziert werden kann, wäre sehr
        interessant für mich gewesen. Leider stellte sich heraus, dass das nicht
        einfach so in wenigen Stunden erlernt werden kann und damit den Rahmen der
        zwei Tage des Hackathons sprengen würde. Eine andere Lösung musste also
        her.
    </p>
    <p>
        Nach einer weiteren Recherche kam mir Elasticsearch über den Weg gelaufen.
        Dieses Tool ist mir während meiner Reise durch die Welt der Technik bereits
        häufiger auf dem Bildschirm erschienen. Ebenfalls weiss ich, dass es in
        vielen für mich interessante Bereiche wie z.B. der Security zum Einsatz
        kommt. Der Fakt, dass Docker schon länger mit der Intension diese
        Technologie ebenfalls zu erlernen auf meiner Festplatte schlummert, lässt
        keine Fragen mehr für den zweiten Lösungsansatz offen.
    </p>
    <p>
        Den Rest des Samstags habe ich also damit verbracht, Elasticsearch in
        Kombination mit dem Web-Service Kibana mit Docker aufzusetzen. Die grösste
        Herausforderung bestand allerdings darin, von <a href="https://huggingface.co/">Huggingface</a> bereits
        trainierte
        Modelle für die Sprachverarbeitung auf Elasticsearch hochzuladen. Ob mir
        das reibungslos gelungen ist? Überhaupt nicht. Die blöden Skripte von der
        Dokumentation haben – wie immer – einfach nicht funktioniert. Und dass
        meine Kenntnisse in Python noch Windeln tragen, hat das alles nicht besser
        gemacht.
    </p>
    <p>
        Nach Stunden des Rausprobierens habe ich es dann doch endlich geschafft,
        die richtigen Pakete zu installieren und irgendwie mit Elasticsearch einem
        Docker-Container zu kommunizieren. Bis ich begriffen habe, dass die Modelle
        nicht von Container selbst heruntergeladen und installiert werden, hat
        bestimmt die längste Zeit gedauert. Die Modelle werden stattessen auf einen
        andern Host heruntergeladen, eine Verbindung mit Elasticsearch aufgebaut,
        um das Modell dann schlussendlich dorthin zu senden. Mit diesem Teilerfolg
        endet der erste Tag des NASA Space App Hackathons.
    </p>
    <h2>
        Tag zwei: Rise after fall
    </h2>
    <p>
        Die Notiz an mich selber «modell machen, das wirklich was macht» zeigt zwei
        Sachen: Erstens, dass es am Vorabend auch schon etwas spät wurde. Zweitens
        zeigt die Notiz, dass meine vergangenen Ichs und ich wirklich gute Teams
        sind, denn ich konnte die Nachricht irgendwie entschlüsseln. Nach dem
        erfolgreichen Hochladen der Modelle stellte sich beim Ausprobieren heraus,
        dass diese nicht für unseren Anwendungsfall erstellt worden sind.
        Dementsprechend fiel die gewünschte Klassifikation der Wörter sehr
        bescheiden aus.
    </p>
    <p>
        An dieser Stelle wünschte ich mir, dass ich die Geschichte mit sowas wie
        «dann habe ich <a href="https://huggingface.co/">Huggingface</a> nach
        passenden Modellen durchforstet, um diese auf Elasticsearch hochzuladen»
        weitergehen würde. Die Realität sah allerdings so aus, dass ich
        Grünschnabel nur verstanden habe, dass ich nicht in der Lage bin, irgendwas
        zu beurteilen geschweige denn etwas von den Texten zu den Modellen zu
        verstehen.
    </p>
    <p>
        Glücklicherweise realisierte ich an dieser Stelle, dass ich den Tag zuvor
        komplett in den Sand gesetzt habe. <a href="https://huggingface.co/">Huggingface</a> hat eine API, an die
        einfach Text gesendet werden kann und netterweise kommt da dann der
        klassifizierte Text zurück. Ohne irgendwelche Eigenleistungen erbringen zu
        müssen. Eigentlich wäre diese Erkenntnis ein grosses Ärgernis, wenn wir
        bedenken, wie sehr limitiert die Zeit des Hackathons ist. Trotzdem freue
        ich mich, erste Erfahrungen mit Docker und Elasticsearch gesammelt zu haben
        und könnte nun rein theoretisch im Lebenslauf die beiden als Erfahrung
        angeben. Theoretisch.
    </p>
    <p>
        Bei meiner Teamkamaradin sah es ähnlich aus. Am Vortag hat sie sich dafür
        entschieden, das Userinterface für die Applikation zu kreieren und dafür
        eine Vorlage zu verwenden. Weil diese so einwandfrei funktioniert hat,
        nimmt die Dinge nun selbst in die Hand und programmiert das Ganze von Grund
        auf selbst. Nachdem wir uns kurz gegenseitig für den nicht optimal
        investierten Tag ausgelacht haben, konnten wir schon bald die ersten
        Erfolge für das Projekt verzeichnen.
    </p>
    <p>
        Von meiner Seite her sehen diese Erfolge so aus, dass ich einen in Python
        geschriebenen Webserver zum Laufen gebracht habe, der den Text vom
        Userinterface entgegennimmt und an die API’s von <a href="https://huggingface.co/">Huggingface</a> zur
        Textklassifikation
        weiterleitet. Mit dem Ergebnis davon kann dann ermittelt werden, welche
        Bilder wir von der NASA brauchen. Mithilfe der <a href="https://opencv.org/">OpenCV</a>-Library habe ich dann
        gelernt,
        einfache Filter zur Änderung der Farbe zu erschaffen. Und damit ist der
        erste Schritt unseres Vorhabens geschafft!
    </p>
    <p>
        Die neue Idee sieht vor, Bilder auf der Basis des eingegebenen Textes
        miteinander verschmelzen zu lassen. Die Klassifikation des Textes erlaubt
        es uns, die Information, wo und wie ein Bild zu einem anderen steht zu
        ermitteln. Was wir allerdings brauchen, ist ein automatischer Prozess, um
        die Bilder miteinander in ein noch nie dagewesenes Kunstwerk zu verwandeln.
    </p>
    <p>
        Um dieses Problem zu lösen, benötigen wir Objekterkennung. Im Textinput
        stehen die Objekte beschrieben, die miteinander verschmolzen werden sollen.
        Die Bilder mit den Objekten erhalten wir von der NASA-API. Diese Bilder
        müssen wir nun nach den gewünschten Objekten absuchen. Auf einem der beiden
        Bilder muss das Objekt dann ausgeschnitten werden, damit dies irgendwo auf
        dem anderen eingefügt werden kann. Die Objekterkennung hilft uns auch
        dabei, die richtige Koordinaten dazu zu ermitteln.
    </p>
    <p>
        Auch für diese Aufgabe kommt <a href="https://opencv.org/">OpenCV</a> zum
        Einsatz. Allerdings ich die Objekterkennung sehr anfängerhaft
        implementiert, was dazu führte, dass es häufig Geister sieht. Die Angst,
        von nun an heimgesucht zu werden, blieb allerdings aus, denn die
        tatsächlich existierende Objekte werden ebenfalls auf dem Bild von
        hässlichen grünen Kästchen umrahmt.
    </p>
    <p>
        An dieser Stelle begannen sich allerdings die Pforten des lokalen Events zu
        schliessen. Für uns hiess das, dass wir das Userinterface und meinen
        rustikalen Server zusammenführen mussten. Das ging zum Glück ziemlich
        problemlos und wir konnten uns noch um die
        <a href="https://2022.spaceappschallenge.org/challenges/2022-challenges/art-worlds/teams/the-calvos/project">
            Formalitäten des Hackathons
        </a>
        kümmern.
    </p>
    <h2>
        Fazit
    </h2>
    <p>
        Zwar hat es nicht ganz gereicht, die Idee gänzlich umzusetzen. Dafür habe
        ich während den zwei Tagen Dinge ausprobieren können, die ich schon seit
        längerem immer wieder passiv auf dem Schirm mitbekommen habe. Auch hatte
        ich meine erste Berührungspunkte mit Machine Learning und habe dadurch ein
        besseres Verständnis dafür erhalten, wie das Ganze in der Praxis gehandhabt
        wird. Ich bin nun sehr motiviert, mich mehr in die Bereiche der Computer
        Vision und Machine Learning zu vertiefen. Durch die oberflächliche
        Auseinandersetzung mit der Materie habe ich dazu auch schon konkrete
        Startpunkte erhalten. Mittlerweile bin ich davon überzeugt, dass falls ich
        die gleiche Challenge mit dem Neugelernten nochmals versuchen würde, auch
        die höheren Ziele erreichen würde.
    </p>
</template>

<script>
export default {

}
</script>

<style>

</style>