<template>
    <div class="technology-list">
        <img v-for="(img, index) in technologies" :key="index" loading="lazy"
            :src="require(`/src/assets/programminglanguages/${img}.png`)" :alt="img">
    </div>
</template>

<script>
export default {
    props: {
        technologies: {
            type: Array,
            required: true
        }
    }
}
</script>

<style>
.technology-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 2em;
}

.technology-list img {
    height: 100px;
}
</style>