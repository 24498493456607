<template>
  <Technologies :technologies="['wordpress', 'html', 'css']"/>
  <div class="content-image">
    <a href="https://diacova.ch" target="_blank">
      <img :src="require('/src/assets/diacova.webp')" alt="Website Diacova.ch" />
    </a>
  </div>
  <div class="text-container">
    <div class="entry">
      <div class="content-text">
        <h2>Hosting</h2>
        <p>
          Für die Realisierung habe ich mich für ein selbst gehostetes WordPress
          entschieden. Die Entscheidung entstand dadurch, dass die individuelle
          Anpassung und ein umfängliches Blogsystem ein grosses Bedürfnis der
          Mitarbeitenden waren. Ausserdem war für ein weiteres Anliegen meines
          Vorgesetzten ein eigenes Webhosting nötig.
        </p>
      </div>
    </div>
    <div class="entry">
      <div class="content-text">
        <h2>WordPress</h2>
        <p>
          WordPress war zu diesem Zeitpunkt noch etwas Neues für mich und ich
          habe mich erstmal einarbeiten müssen. Die grösste Herausforderung war
          und ist weiterhin, dass ich Änderungen nicht einfach programmieren
          kann, sondern herausfinden muss, welche Knöpfe ich im UI klicken muss.
          Glücklicherweise gibt es die Möglichkeit, ein eigener Code mit
          einzubinden, was mir im Zweifelsfall erlaubt, darauf zugreifen.
        </p>
      </div>
    </div>
    <div class="entry">
      <div class="content-text">
        <h2>Interesse</h2>
        <p>
          Die Beschäftigung mit WordPress hat mich soweit interessiert, dass ich
          in meiner Freizeit ein kleines Theme und Plugin dafür programmiert
          habe. So lernte ich nebenbei auch, was hinter den Kulissen des
          beschränkten Interfaces abläuft.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Technologies from "../Technologies.vue";

export default {
  components: {
    Technologies
  }
};
</script>

<style>
.content-image {
  width: 90%;
  margin: 0 auto;
}

.content-image img {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .content-image {
    width: 50%;
  }
}
</style>