<template>
  <div class="videosection-container">
    <div class="videosection" v-bind:class="{ left: left }">
      <div class="video">
        <div v-if="$slots.default" class="slots">SLOTS<slot></slot>
        </div>
        <video v-else-if="media.split('.')[1] == 'mp4'" controls :src="require(`/src/assets/${media}`)"></video>
        <img v-else :src="require(`/src/assets/${media}`)" :alt="alt" />
        <div class="glitch">
          <div class="glitch1"></div>
          <div class="glitch2"></div>
          <div class="glitch3"></div>
          <div class="glitch4"></div>
        </div>
      </div>
      <div class="text">
        <h2>{{ title }}</h2>
        <p v-html="text"></p>
        <router-link v-if="link != undefined" :to="{name: link, params: linkParams}">
          <button>{{linkText}}</button>
        </router-link>
      </div>
    </div>

    <div :id="uid" class="background"></div>
  </div>
</template>

<script>
let uid = 0;
export default {
  name: "Videosection",
  props: {
    title: String,
    text: String,
    media: String,
    left: Boolean,
    alt: String,
    link: String,
    linkText: String,
    linkParams: Object,
  },
  mounted() {
    //background glitch
    let images = [];
    let random = Math.floor(Math.random() * 7 + 1);
    for (let i = random; i < random + 3; i++) {
      images[i] = require(`/src/assets/glitchimages/Glitch_${i + 1}.png`);
    }
    images = images.filter((item) => item);
    let bgContainer = document.querySelector("#" + this.uid);
    let i = 1;
    images.forEach((image) => {
      let img = document.createElement("img");
      img.src = image;
      img.style.animation = "glitch linear 4.5s infinite " + i * 3 + "s";
      img.setAttribute("aria-hidden", "true");
      img.setAttribute("alt", "glitch_background");

      bgContainer.append(img);
      i++;
    });
  },
  data() {
    uid += 1;
    return {
      uid: `bg-${uid}`,
    };
  },
};
</script>

<style>
.videosection-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.videosection {
  display: flex;
  min-height: 500px;
  width: 100%;
  flex-direction: column;
}

.videosection.left {
  flex-direction: column;
}

.left::before {
  content: "";
  position: absolute;
  height: 50px;
  width: 50px;
  border-top: 4px var(--white) solid;
  border-right: 4px var(--white) solid;
  top: 2em;
  right: 2em;
  opacity: 0.7;
}

.left::after {
  content: "";
  position: absolute;
  height: 50px;
  width: 50px;
  border-left: 4px var(--white) solid;
  border-bottom: 4px var(--white) solid;
  left: 2em;
  bottom: 2em;
  opacity: 0.7;
}

.videosection>div {
  width: 100%;
  z-index: 10;
}

.video {
  flex-basis: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text {
  flex-basis: 2;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
}

.text p {
  width: 100%;
}

.video video,
.video img {
  display: block;
  width: 90%;
  z-index: 50;
  border: 5px rgba(0, 0, 0, 0.514) solid;
  background: black;
}

.background {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.background img {
  position: absolute;
  opacity: 0;
  left: 0;
}

.glitch {
  position: absolute;
  inset: 0;
}

.glitch>div {
  opacity: 0.8;
  animation: fade 5s ease infinite;
}

.glitch .glitch1 {
  position: absolute;
  width: 265px;
  height: 200px;
  background-color: #313131;
  top: 60px;
  left: 60px;
  animation-delay: 0s;
}

.glitch .glitch2 {
  position: absolute;
  width: 140px;
  height: 80px;
  background-color: #4d4d4d;
  bottom: 130px;
  left: 60px;
  animation-delay: 1s;
}

.glitch .glitch3 {
  position: absolute;
  width: 265px;
  height: 60px;
  background-color: #1b1b1b;
  bottom: 60px;
  left: 200px;
  z-index: 2;
  animation-delay: 2s;
}

.glitch .glitch4 {
  position: absolute;
  width: 265px;
  height: 200px;
  background-color: #313131;
  bottom: 90px;
  right: 60px;
  animation-delay: 3s;
}

@keyframes glitch {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0.5;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0.8;
    transform: translate(0, 0px);
  }

  50% {
    opacity: 0.7;
    transform: translate(3px, 3px);
  }

  100% {
    opacity: 0.8;
    transform: translate(0px, 0px);
  }
}

@media screen and (min-width: 1024px) {
  .videosection {
    display: flex;
    min-height: 500px;
    width: 100%;
    flex-direction: row;
  }

  .videosection>div {
    width: 50%;
    z-index: 10;
  }

  .videosection.left {
    flex-direction: row-reverse;
  }

  .video {
    padding-right: 4em;
    padding-top: 1em;
    padding: 1em 2em;
  }

  .text {
    padding-left: 4em;
    text-align: left;
  }

  .text p {
    width: 85%;
  }

  .videosection-container {
    padding: 2em;
  }

  .video video,
  .video img {
    width: 80%;
  }
}
</style>