<template>
  <div class="content-text">
    <p>
      <strong><i>Kontakt-Adresse</i></strong>
    </p>
    <p>Jeremy Glaus<br />Bernstrasse 8<br />3114 Wichtrach<br />Schweiz</p>
    <p>E-Mail:<br />jeremy.glaus@yahoo.com</p>
    <br /><br />
    <p>
      <strong><i>Haftungsausschluss</i></strong>
    </p>
    <p>
      Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich
      vor, Teile der Seiten oder das gesamte Angebot ohne besondere Ankündigung
      zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
      oder endgültig einzustellen.
    </p>
    <br /><br />
    <p>
      <strong><i>Urheberrechte</i></strong>
    </p>
    <p>
      Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
      anderen Dateien auf dieser Website, gehören ausschliesslich
      <strong>Jeremy Glaus</strong> oder den speziell genannten Rechteinhabern.
      Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung
      des Urheberrechtsträgers im Voraus einzuholen.
    </p>
    <br /><!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall geahndet wird.-->Quelle:
    <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener"
      >SwissAnwalt</a
    ><!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->
  </div>
</template>