<template>
  <Technologies :technologies="['asm', 'c', 'cpp', 'java', 'html', 'css', 'javascript']"/>
  <div class="videos">
    <div class="entry">
      <VideoSection media="spacereader.mp4" title="Space News Reader"
        text="Die Aufgabenstellung für dieses Projekt war, dass mit einem API-Request Daten von der Spaceflight News API abgeholt und angezeigt werden können. Das Ganze sollte mit mehreren Sprachen funktionieren, mit schönem und dokumentiertem Code erstellt worden sein und mit Maven gestartet werden können. Als zusätzliche Herausforderung habe ich ein lazy loading für die Einträge implementiert und gelernt, wie Bilder asynchron geladen werden können. " />
    </div>

    <div class="entry">
      <VideoSection media="shell.mp4" :left="true" title="Shell"
        text="Dieses Projekt war eine riesige Herausforderung. Gefordert war eine in C geschriebene Anwendung, mit der grundlegende Shell-Befehle ausgeführt werden können. Für die Implementation war es notwendig, dass ich mich mit verschiedenen Konzepten von Linux und der C-Programmiersprache vertraut machen musste." />
    </div>

    <div class="entry">
      <VideoSection media="base.mp4" title="Base64 kodierung in Assembler"
        text="Das Base64-Projekt in Assembler zu programmieren hat mir nicht gefallen und es hat mir keine Freude bereitet. Im Rückblick allerdings bin ich sehr froh das Projekt durchgestanden zu haben, denn seither habe ich ein grösseres Grundverständnis dafür, was ein Computer den lieben langen Tag so macht. " />
    </div>

    <div class="entry">
      <VideoSection media="bnb.mp4" :left="true" title="Branch and Bound"
        text="Aus Neugier habe ich mich für das advanced Modul für Datenstrukturen und Algorithmen eingeschrieben. Während dieser Zeit wurde uns jede Woche neue Aufgaben zum Erledigen mit auf den Weg gegeben. Eine dieser Aufgabe besteht aus der Implementierung des Branch and Bound Algorithmus und dessen Visualisierung mittels JavaFX. Auffallend ist, dass mein Ergebnis dabei nicht wirklich optisch ansprechend ist. Dennoch bin ich sehr zufrieden, denn die Aufgabe konnte ich nach nicht mal einem Tag bereits abhaken. Das Projekt kann auf <a href='https://github.com/Jeremy-Glaus/Branch-and-bound/tree/main' target='_blank'>GitHub</a> gefunden werden" />
    </div>

    <div class="entry bg-grey mentions">
      <div class="content-text">
        <h2>Honorable Mentions</h2>
      </div>
      <p class="intro">
        Die oben genannten Projekten wurden alle von mir alleine konzipiert und durchgeführt. Ich habe mich dagegen
        entschieden, Projekte mit einzubinden, die entweder innerhalb einer Gruppe oder mithilfe einer gegebenen
        Vorlage erstellt wurden. Dennoch gibt es einige Projekte, die ich gerne kurz erwähnen möchte.
      </p>
      <SplitComponent :entries="honorableMentions" />
    </div>
  </div>
</template>

<script>
import VideoSection from "../Videosection.vue";
import SplitComponent from "../SplitComponent.vue";
import Technologies from "../Technologies.vue";
export default {
  components: {
    VideoSection,
    SplitComponent,
    Technologies
  },
  data: function () {
    return {
      honorableMentions: [
        { title: "Actor Race", text: "Während einer Blockwoche haben wir uns mit dem Akka-Framework in Java auseinandergesetzt. Um das neue Wissen zu praktizieren, wurde uns die Aufgabe gestellt, in einem Labyrinth gefangene Actors mit verschiedenen Strategien zu versehen, um sie beim Finden des Ausganges zu unterstützen. " },
        { title: "Drawing App", text: "Das C++ Modul hatte sehr viele Möglichkeiten, selbst aktiv zu werden und zu programmieren. Zu Beginn wurde uns eine Projektvorlage mit laufender OpenGL-Integration zur Verfügung gestellt. Auf dessen Grundlage haben wir über das Semester durch eine Applikation entwickelt, mit der auf der Basis von geometrischen Objekten gezeichnet werden kann." },
        { title: "Network Devices", text: "Während wir auf dem theoretischen Wege in die Welt der Netzwerke eintauchten, konnten wir im Projekt-Modul dieses Wissen direkt anwenden. In einer Ubuntu-Entwicklungsumgebung haben wir so mit C unsere eigene Hub, Switch, Arp-Implementierung und Router programmiert." },
        { title: "Academia", text: "Um die Prinzipien des Software Engineerings zu praktizieren, haben wir basierend auf den Webprogrammiersprachen, Tomcat und einem Postgre eine Plattform zur Verwaltung von Modulen und Noten entwickelt. " },
        { title: "RSA Verschlüsselung", text: "In der diskreten Mathematik haben wir uns die RSA-Verschlüsselung und dessen Beweis angeschaut. Daraus hat sich später der Auftrag ergeben, unsere eigene Implementation mit Java zu erstellen. Die Implementation ist in der Lage, Nachrichten zu ver-/ und entschlüsseln. Die Funktionalität, mittels dem fermatschem Satz schwache Verschlüsselungen zu brechen, ist ebenfalls mit integriert." },
      ]
    }
  }
};
</script>

<style>
  .mentions{
    padding: 6em;
  }
</style>