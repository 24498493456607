<template>
  <ProjectSelction
    :projects="projects"
    @projectselect="selectProject($event)"
  />

  <h1>{{ projects[selectedProject].name }}</h1>

  <p class="intro">
    {{ projects[selectedProject].intro }}
  </p>

  <div class="projectdetails">
    <component v-bind:is="projects[selectedProject].component"></component>
  </div>

</template>

<script>
import ProjectSelction from "/src/components/ProjectSelection.vue";

import Study from "/src/components/projects/Study.vue";
import Diacova from "/src/components/projects/Diacova.vue";
import Gamejams from "/src/components/projects/Gamejams.vue";
import Shop from "/src/components/projects/Shop.vue";

export default {
  components: {
    ProjectSelction,
  },
  data: function () {
    return {
      selectedProject: 0,
      projects: [
        {
          name: "STUDIUM",
          url: "study",
          component: Study,
          intro:
            "Innerhalb des Studiums haben wir eine Reihe von Projekten erledigt. Gerne stelle ich im Folgenden einige davon vor, die mir am meisten gefallen oder mich herausgefordert haben.",
        },
        {
          name: "WEBSITE DIACOVA",
          url: "website_diacova",
          component: Diacova,
          intro:
            "Im Rahmen meiner Anstellung bei der Diacova erhielt ich die Möglichkeit, die Website neu zu gestalten.",
        },
        {
          name: "SHOP",
          url: "shop",
          component: Shop,
          intro:
            "Während meiner Berufslehre als Kaufmann habe ich in meiner Freizeit Kenntnisse über die Webprogrammierung erworben. Ein von mir lang angesetzter Meilenstein war es, ein Shop von Grund auf selbst programmiert zu haben, welches wie folgt aufgebaut war:",
        },
        {
          name: "GAMEJAMS",
          url: "shop",
          component: Gamejams,
          intro:
            "Einer meiner grossen Träume ist es, eines Tages mein eigenes Openworld-Spiel zu entwickeln! In der Vergangenheit habe ich mich deshalb immer wieder etwas mit Gamedevelopment auseinandergesetzt und das erarbeitete Wissen innerhalb von Gamejams angewendet. Eine Gamjam ist mit einem Hackathon zu vergleichen: Innerhalb von 48 Stunden gilt es, ein laufendes Spiel von Grund auf zu entwickeln. Jedes Jahr gibt es eine global organisierte Gamejam zu einem bestimmten Thema.",
        },
      ],
    };
  },
  methods: {
    selectProject: function (id) {
      this.selectedProject = id;
    }
  },
};
</script>

<style>
.projectdetails {
  width: 100%;
}

.intro {
  font-size: 1.3em;
  font-weight: bolder;
  text-align: center;
  width: 100%;
  padding: 1rem;
}

@media screen and (min-width: 1024px) {
  .intro {
    font-size: 1.3em;
    font-weight: bolder;
    text-align: center;
    width: 50%;
    margin-left: 25%;
    margin-bottom: 10vh;
    padding: 1rem;
  }
}
</style>