<template>
  <div class="content-text">
    <h2>Datenschutzerkl&auml;rung</h2>
    <p>
      Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
      EU-Datenschutzgrundverordnung (DSGVO), ist:
    </p>
    <br />
    <p>Jeremy Glaus<br />Bernstrasse 8<br />3114 Wichtrach</p>
    <p>
      E-Mail: jeremy.glaus@yahoo.com<br />WebSite:
      www.jeremyglaus.dev
    </p>
    <p>&nbsp;</p>
    <h2>Allgemeiner Hinweis</h2>
    <p>
      Gest&uuml;tzt auf Artikel 13 der schweizerischen Bundesverfassung und den
      datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG<!--DSG-->)
      hat jede Person Anspruch auf Schutz ihrer Privatsph&auml;re sowie auf
      Schutz vor Missbrauch ihrer pers&ouml;nlichen Daten. Die Betreiber dieser
      Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir
      behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
      gesetzlichen Datenschutzvorschriften sowie dieser
      Datenschutzerkl&auml;rung.
    </p>
    <p>
      In Zusammenarbeit mit unseren Hosting-Providern bem&uuml;hen wir uns, die
      Datenbanken so gut wie m&ouml;glich vor fremden Zugriffen, Verlusten,
      Missbrauch oder vor F&auml;lschung zu sch&uuml;tzen.
    </p>
    <p>
      Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B.
      bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann.
      Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist
      nicht m&ouml;glich.
    </p>
    <p>
      Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit der Erhebung,
      Verarbeitung und Nutzung von Daten gem&auml;ss der nachfolgenden
      Beschreibung einverstanden. Diese Website kann grunds&auml;tzlich ohne
      Registrierung besucht werden. Dabei werden Daten wie beispielsweise
      aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu
      statistischen Zwecken auf dem Server gespeichert, ohne dass diese Daten
      unmittelbar auf Ihre Person bezogen werden. Personenbezogene Daten,
      insbesondere Name, Adresse oder E-Mail-Adresse werden soweit m&ouml;glich
      auf freiwilliger Basis erhoben. Ohne Ihre Einwilligung erfolgt keine
      Weitergabe der Daten an Dritte.
    </p>
    <p>&nbsp;</p>
    <h2>Datenschutzerkl&auml;rung f&uuml;r SSL-/TLS-Verschl&uuml;sselung</h2>
    <p>
      Diese Website nutzt aus Gr&uuml;nden der Sicherheit und zum Schutz der
      &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
      Sie an uns als Seitenbetreiber senden, eine SSL-/TLS-Verschl&uuml;sselung.
      Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
      Adresszeile des Browsers von &quot;http://&quot; auf &quot;https://&quot;
      wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
    </p>
    <p>
      Wenn die SSL bzw. TLS Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die
      Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen
      werden.
    </p>
    <p>&nbsp;</p>
    <h2>Datenschutzerkl&auml;rung für die Nutzung von Google Web Fonts</h2>
    <p>
      Diese Website nutzt zur einheitlichen Darstellung von Schriftarten so
      genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
      einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in ihren
      Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Wenn Ihr
      Browser Web Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von
      Ihrem Computer genutzt.
    </p>
    <p>
      Weitere Informationen zu Google Web Fonts finden Sie unter
      <a
        href="https://developers.google.com/fonts/faq"
        target="_blank"
        style="color: inherit"
        >https://developers.google.com/fonts/faq</a
      >
      und in der Datenschutzerkl&auml;rung von Google:
      <a
        href="https://www.google.com/policies/privacy/"
        target="_blank"
        style="color: inherit"
        >https://www.google.com/policies/privacy/</a
      >
    </p>
    <p>&nbsp;</p>
    <h2>Verwendung von Matomo</h2>
    <p>
      Diese Website benutzt Matomo (ehemals Piwik), eine Open-Source-Software
      zur statistischen Auswertung von Besucherzugriffen. Matomo in einem Modus
      verwendet, mit dem keine Cookies verwendet werden. Die IP-Adresse wird unmittelbar
      nach der Verarbeitung und vor deren Speicherung anonymisiert.
    </p>
    <p>&nbsp;</p>
    <h2>&Auml;nderungen</h2>
    <p>
      Ich kann diese Datenschutzerkl&auml;rung jederzeit ohne
      Vorank&uuml;ndigung anpassen. Es gilt die jeweils aktuelle, auf meiner
      Website publizierte Fassung. Soweit die Datenschutzerkl&auml;rung Teil
      einer Vereinbarung mit Ihnen ist, werde ich Sie im Falle einer
      Aktualisierung über die &Auml;nderung per E-Mail oder auf andere geeignete
      Weise informieren.
    </p>
    <p>&nbsp;</p>
    <h2>Fragen zum Datenschutz</h2>
    <p>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie mir bitte eine E-Mail! Die
      Kontaktdaten finden Sie an erster Stelle der Datenschutzerklärung.
    </p>
    <p>&nbsp;</p>
    <p>
      <!--ACHTUNG: Wenn Sie die Quelle ohne Erlaubnis von SwissAnwalt entfernen, dann begehen Sie eine Urheberrechtsverletzung welche in jedem Fall unter Kostenfolge geahndet wird.--><br />Quelle:
      <a href="https://www.swissanwalt.ch" target="_blank" rel="noopener"
        >SwissAnwalt</a
      >
    </p>
    <!--Bitte beachten Sie die AGB von SwissAnwalt betreffend allfällig anfallenden Kosten bei Weglassen der Quelle!-->
  </div>
</template>