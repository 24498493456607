<template>
  <h1>About Me</h1>
  <VideoSection title="Guten Tag!"
    text="Mein Name ist Jeremy Glaus und ich bin 23 Jahre alt. Ich bin ausgelernter Kaufmann und studiere im Moment berufsbegleitend Informatik an der Berner Fachhochschule."
    media="jeremy.webp" alt="Bild von Jeremy" />
  <div class="text-container">
    <div class="entry">
      <div class="content-text">
        <h2>Technisches Flair</h2>
        <p>
          Während der Ausbildung zum Kaufmann wurde die grosse Neugier geweckt und ich habe mich seither mit Büchern und
          diversen Tutorials im Internet mit dem Programmieren beschäftigt. Damit kann ich aktuell ungefähr 6 Jahre
          Erfahrung in der Programmierung ausweisen. Als die kaufmännische Berufsbildung dann zu Ende ging, war mir der
          nächste Schritt mehr als klar gewesen: Das Informatikstudium. Seit 2021 verfeinere und erweitere ich meine
          Kenntnisse sowie Fähigkeiten in diesem Fachgebiet weiter und merke, wie sehr es mich weiterhin mit Freude
          erfüllt.
        </p>
      </div>
    </div>
    <div class="entry">
      <div class="content-text">
        <h2>Freizeit</h2>
        <p>
          In meiner Freizeit beschäftige ich mich gerne mit allem, was
          technisches Geschick erfordert. Auch interessiere ich mich für
          kreative Aktivitäten, wie beispielsweise Animationen, die ich mittels
          After Effects erstelle. Im Moment bilde ich mich selbstständig im
          Zeichnen und den weiteren Gebieten im 3D-Bereich weiter.
        </p>
        <p>
          Ausserhalb dieser sonst gewohnten Umgebung bin ich sehr an Games
          interessiert. Diese Interesse geht soweit, dass eines meiner grössten
          Ziele darin besteht, selbst einmal eines mit einem riesigen Umfang zu
          kreieren. Seit ungefähr 2 Jahren bin ich dabei, Ideen für die
          Geschichte, Charaktere, Orte, Mechaniken und vieles mehr zu sortieren
          und zu arrangieren.
        </p>
        <p>
          Zudem habe ich auch noch einige herkömmlichere Hobbys, die aber
          weniger spannend aufzuschreiben sind: Ich spiele Klavier und lerne die
          Kunst des Ninjutsus.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VideoSection from "../components/Videosection.vue";
export default {
  name: "Home",
  components: {
    VideoSection,
  },
  props: {
    title: {
      require: true,
      type: String
    },
    description: {
      require: true,
      type: String
    }
  },
  mounted() {

  }
};
</script>
