<template>
  <nav id="nav">
    <router-link to="/"><span>HOME</span></router-link>
    <router-link to="/timeline">LEBENSLAUF</router-link>
    <router-link to="/projects">PROJEKTE</router-link>
    <router-link to="/blog">BLOG</router-link>
  </nav>

  <main>
    <router-view />
    <button class="fadeOut" id="scrolltop" @click="scrollTop()">^</button>
  </main>

  <footer>
    <router-link to="/impressum">Impressum</router-link>
    <router-link to="/datenschutz">Datenschutz</router-link>
  </footer>
</template>

<script>
export default {
  created() {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 150) {
        document.getElementById("scrolltop").classList.remove("fadeIn")
        document.getElementById("scrolltop").classList.add("fadeOut")
      } else {
        document.getElementById("scrolltop").classList.add("fadeIn")
        document.getElementById("scrolltop").classList.remove("fadeOut")
      }
    });
  },
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style>
:root {
  --navheight: 65px;
  --primary: #8387ff;
  --primary50: #8387ff7c;
  --primary10: #8387ff17;
  --white: #e2e2e2;
  --grey: #a0a0a0;
  --darkgrey: rgb(14, 14, 14);
}

::selection {
  background-color: var(--primary50);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--darkgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--primary10);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary50);
}

* {
  box-sizing: border-box;
}

.bg-grey{
  background-color: var(--darkgrey);
}

footer {
  width: 100%;
  padding: 1em;
  display: flex;
  justify-content: center;
}

footer a {
  text-decoration: none;
  color: var(--white);
  padding: 10px;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--darkgrey);
}

#app {
  color: var(--white);
  font-family: "Comfortaa", cursive;
  line-height: 1.5;
}

h1 {
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 4rem;
  padding-top: calc(var(--navheight));
}

h2 {
  font-family: "Work Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

.content-text h2{
  text-align: center;
}

p {
  text-align: left;
  font-size: 1.1em;
}

button {
  color: var(--white);
  font-family: "Comfortaa", cursive;
  line-height: 1.5;
  font-size: 1.1em;
  padding: 1rem 2rem;
  background-color: var(--primary50);
  border: none;
  cursor: pointer;
  transition-duration: 250ms;
}

button:hover {
  background-color: var(--primary);
}

#scrolltop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  padding: 12px;
  border-radius: 50%;
  border: none;
  background-color: var(--primary10);
  font-size: 2em;
  cursor: pointer;
  z-index: 100;
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.5);
}

#nav {
  height: var(--navheight);
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Work Sans", sans-serif;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
}

main {
  margin-top: calc(var(--navheight));
}

#nav span {
  font-family: "Work Sans", sans-serif;
}

#nav a {
  font-weight: 400;
  position: relative;
  color: var(--grey);
  text-decoration: none;
  padding: 20px;
  overflow: hidden;
}

/* text active */
#nav a.router-link-exact-active {
  color: var(--white);
  cursor: default;
}

/* upper triangle */
#nav a.router-link-exact-active::before {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  top: 13px;
  height: 11px;
  width: 11px;
  border-left: 2px var(--white) solid;
  border-top: 2px var(--white) solid;
  animation: blinkfade 0.3s linear;
}

/* bottom triangle */
#nav a.router-link-exact-active::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 13px;
  height: 11px;
  width: 11px;
  border-bottom: 2px var(--white) solid;
  border-right: 2px var(--white) solid;
  animation: blinkfade 0.3s linear;
}

/* nav hover text */
#nav a:hover:not(.router-link-exact-active) {
  color: var(--white);
}

/* nav hover bottom triangle */
#nav a:hover:not(.router-link-exact-active)::before {
  content: "";
  position: absolute;
  right: 10px;
  bottom: 13px;
  height: 11px;
  width: 11px;
  border-bottom: 2px var(--grey) solid;
  border-right: 2px var(--grey) solid;
  animation: bottomTriangleIn 0.2s ease-in-out;
}

/* nav hover upper triangle */
#nav a:hover:not(.router-link-exact-active)::after {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  top: 13px;
  height: 11px;
  width: 11px;
  border-left: 2px var(--grey) solid;
  border-top: 2px var(--grey) solid;
  animation: upperTriangleIn 0.2s ease-in-out;
}

@keyframes bottomTriangleIn {
  0% {
    transform: translate(20px, 20px);
    opacity: 0;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes upperTriangleIn {
  0% {
    transform: translate(-20px, -20px);
    opacity: 0;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes blinkfade {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.1;
  }

  75% {
    opacity: 0.8;
  }

  90% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeInAnimation 0.2s ease-in-out forwards;
}

.fadeOut {
  animation: fadeOutAnimation 0.2s ease-in-out forwards;

}

@keyframes fadeInAnimation {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

@keyframes fadeOutAnimation {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}


.content-text {
  width: 100%;
  max-width: 75ch;
  margin: 0 auto;
  padding: 1rem;
}

.content-text p {
  position: relative;
}

.content-text p::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 0.25em;
  bottom: 0.25em;
  width: 4px;
  background-color: var(--primary50);
}

.entry {
  margin-top: 5vh;
}

.text-container {
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.text-container .entry {
  margin-top: 0;
}

a {
  color: var(--primary);
}

a:hover {
  color: var(--primary50);
}

.videos {
  background-color: black;
}
</style>
