<template>
    <div class="bloglist">
        <h1>Hello World!</h1>
        <BlogList></BlogList>
    </div>
</template>

<script>
import BlogList from "@/components/blog/BlogList.vue"

export default {
  components: {
    BlogList
  },
  props: {
    blogname: {
      type: String,
    }
  }
}
</script>

<style>

</style>