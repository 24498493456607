<template>
  <div class="textcontainer">
      <p>{{text}}</p>
  </div>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>