import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Projects from "../views/Projects.vue";
import Timeline from "../views/Timeline.vue";
import Contact from "../views/Contact.vue"
import Impressum from "../views/Impressum.vue"
import Datenschutz from "../views/Datenschutz.vue"
import Blog from "../views/Blog.vue"

import BlogArticle from "../components/blog/BlogArticle.vue"

import NotFound from "../views/errors/NotFound.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Jeremy Glaus | Portfolio",
      description: "Herzlich willkommen auf meinem Portfolio! Hier erzähle ich dir gerne etwas mehr zu meiner Person."
    }
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
    meta: {
      title: "Jeremy Glaus | Projekte",
      description: "Auswahl an Projekten, die ich durchgeführt habe"
    }
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: Timeline,
    meta: {
      title: "Jeremy Glaus | Lebenslauf",
      description: "Lebenslauf"
    }
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    meta: {
      title: "Jeremy Glaus | Impressum",
      description: "Impressum und rechtliche Informationen"
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Jeremy Glaus | Kontakt",
      description: "testdescription"
    }
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz,
    meta: {
      title: "Jeremy Glaus | Datenschutz",
      description: "Datenschutz und Informationen zur Datenverarbeitung"
    }
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: {
      title: "Jeremy Glaus | Blog",
      description: "Einblicke in hobbymässige Projekte oder Themen rund um die IT, die mich beschäftigen"
    }
  },
  {
    path: '/blog/:blogdata',
    name: "Blogarticle",
    component: BlogArticle,
    props: true,
    meta: {
      title: "Jeremy Glaus | Blog",
      description: "Einblicke in hobbymässige Projekte oder Themen rund um die IT, die mich beschäftigen"
    }
  },
  {
    name: "404",
    path: '/:catchAll(.*)*',
    component: NotFound,
    meta: {
      title: "Jeremy Glaus | 404",
      description: "Seite nicht gefunden"
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.querySelector('meta[name="description"]').content = to.meta.description;
  document.querySelector('title').innerHTML = to.meta.title;
  next();
});

export default router;
