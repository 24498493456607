<template>
  <Technologies :technologies="['php', 'mariadb', 'html', 'css', 'javascript']" />
  <div class="videos">
    <div class="entry">
      <VideoSection media="backoffice.mp4" title="Admin-Bereich"
        text="Im Admin-Bereich ist es möglich, neue Benutzer zu erstellen und zu verwalten. Diese ermöglichen das Einsehen sowie Bearbeiten von  Produkten oder Kontaktanfragen, wobei. getätigte Bestellungen eingesehen werden können. Anhand der Daten evaluiert das System automatisch, welche Produkte gut verkauft werden und damit auf der ersten Seite im Shop angezeigt werden sollen." />
    </div>

    <div class="entry">
      <VideoSection media="homepage.mp4" title="Hauptseite" :left="true"
        text="Die Hauptseite soll den Shop und die dahinterstehenden Werte etwas vorstellen. Die technische Umsetzung davon war sehr interessant, denn ich habe mich mit dem Bodymovin-Framework von Airbnb auseinandergesetzt und konnte so mithilfe von After Effects Animationen direkt in die Website einbauen. Auch die Erstellung des Titelbildes war sehr spannend, denn dafür habe ich mich mit der 3D-Software Blender auseinandersetzen müssen, um die genaue Vorstellung davon zu realisieren." />
    </div>

    <div class="entry">
      <VideoSection media="shoppage.mp4" title="Shopseite"
        text="Auf der Shop-Seite können die Produkte eingesehen, dem Warenkorb hinzugefügt und schlussendlich erworben werden. Der Aufbau ist so gestaltet, dass die Produktdetails mittels Popups angesehen werden können." />
    </div>
  </div>
  <div class="text-container">
    <div class="entry">
      <div class="content-text">
        <h2>Projektende</h2>
        <p>
          Mit dem Ende der theoretischen beruflichen Grundbildung stand bereits
          das einjährige Praktikum vor der Tür, um die Lehre abzuschliessen. Ich
          habe mich in dieser Zeit mehr auf die berufliche Karriere fokussiert
          und deshalb das Projekt mit dem Shop eingestellt.
        </p>
      </div>
    </div>

    <div class="entry">
      <div class="content-text">
        <h2>Fazit</h2>
        <p>
          In diesem Projekt bin ich mit den gängigen Webprogrammiersprachen sehr
          vertraut worden. Mit einem funktionierenden von Grund auf selbst
          zusammengebastelten Shop hatte ich dann das Selbstvertrauen, Aufträge
          für die freiberufliche Programmierung anzunehmen.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VideoSection from "../Videosection.vue";
import Technologies from "../Technologies.vue";

export default {
  components: {
    VideoSection,
    Technologies
  },
};
</script>

<style>

</style>