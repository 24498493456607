<template>
    <Technologies :technologies="['blender', 'unity', 'ae', 'pr', 'ps', 'au']" />

    <div class="videos">
        <div class="entry">
            <VideoSection media="spuwirits.webp" title="GGJ 2022: Spuwurits"
                text="Das Motto “Dualität” haben wir uns so zu Herzen genommen, dass zwei Geister mit gemeinsam Aufgaben lösen müssen. Der eine Geist befindet sich in einem Keller und erleidet Schaden, wenn sich dieser fernab einer Lichtquelle befindet. Glücklicherweise befindet sich der zweite Geist an der Oberfläche und kann den Weg erleuchten." />
        </div>

        <div class="entry">
            <VideoSection media="lost_memory.webp" :left="true" title="GGJ 2021: Lost Memory"
                text="Mit dem Motto “Lost and Found” begleiten wir den Roboter Marvin dabei, wie er seine Erinnerungen verloren hat und diese nach und nach wieder rekonstruiert. Begleitet wird die Geschichte davon, dass unser Roboterheld seiner Arbeit, dem Paketzustellen, nachgeht." />
        </div>
    </div>

    <div class="entry">
        <div class="content-text">
            Die Spiele und mehr Informationen zu den Gamejams kann <a href="https://globalgamejam.org/users/schnarch"
                target="_blank">hier</a> gefunden werden.
        </div>
    </div>
</template>
  
<script>
import VideoSection from "../Videosection.vue";
import Technologies from "../Technologies.vue";
export default {
    components: {
        VideoSection,
        Technologies
    }
};
</script>
  
<style>

</style>