<template>
    <div class="split">
        <h3>{{title}}</h3>
        <p>{{text}}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
}
</script>

<style>
    .split{
        width: 45%;
        padding: 2em;
    }
</style>