<template>
    <div class="split-container">
        <SplitEntry v-for="(entry, index) in entries" :title="entry.title" :text="entry.text" :key="index" />
    </div>
</template>

<script>
import SplitEntry from "./SplitEntry.vue"

export default {
    components: { SplitEntry },
    props: {
        entries: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>
.split-container{
    display: flex;
    flex-wrap: wrap;
}
</style>