<template>
  <div class="container">
    <div class="content">
      <span class="title">not yet imagined</span>
      <span class="subtitle">Die aufgerufene Seite wurde bisher noch nicht kreiert. Bitte klicke auf einen Link in der Navigation</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

.container {
  height: calc(100vh - 55px);
  width: 100vw;
  color: var(--white);
  background-image: url('../../assets/galaxy.png');
  z-index: -1;
}

.container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem;
}

.subtitle {
  font-size: 2rem;
  color: var(--lightgrey);
  text-align: center;
}

.container .title {
  font-size: 6em;
  text-align: center;
}

.content {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
</style>