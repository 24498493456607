<template>
  <div class="selection">
    <button
      class="projectbutton"
      v-for="(project, id) in projects"
      :key="id"
      v-bind:class="{active : id == active}"
      @click="handleProjectClick(id)"
      aria-multiselectable="true"
      :aria-selected="id==active"
    >
      <ProjectButton :text="project.name" />
    </button>
  </div>
</template>

<script>
import ProjectButton from "/src/components/ProjectButton.vue";

export default {
  components: {
    ProjectButton,
  },
  props: {
    projects: Array,
  },
  emits: ["projectselect"],
  methods: {
    handleProjectClick: function (id) {
      this.active = id;
      this.$emit("projectselect", id);
    },
  },
  data: function(){
    return{
      active: 0
    }
  }
};
</script>

<style>
.selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  width: 100vw;
  padding: 2em;
  position: relative;
  overflow: hidden;
}

.projectbutton {
  background-color: transparent;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 10px;
  flex-basis: 12.5%;
  z-index: 10;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
  transition-duration: 250ms;
  overflow: hidden;
  border: 2px black solid;
}

.projectbutton:hover {
  box-shadow: 0px 0px 0px 8px var(--primary10);
  border: 0px black solid;
}

.selection .active {
  box-shadow: 0px 0px 0px 8px var(--primary50);
  border: 0px black solid;
}
</style>