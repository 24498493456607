<template>
  <h1>Lebenslauf</h1>
  <div class="timeline">
    <div class="year" v-for="(year, index) in archivements" :key="index">
      <div class="yeartitle">
        <h2>{{ year.year }}</h2>
      </div>

      <div class="points">
        <div
          class="archivement"
          v-for="(archivement, index) in year.archivements"
          :key="index"
        >
          {{ archivement }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Werdegang",
  data: function () {
    return {
      currentIndex: 0,
      archivements: [
        {
          year: 2016,
          archivements: [
            "BWD Bern, kaufmännische Brufslehre",
          ],
        },
        {
          year: 2019,
          archivements: [
            "BWD Bern, Ende theoretische Grundbildung",
            "Diacova AG, kaufmännisches Praktikum",
          ],
        },
        {
          year: 2020,
          archivements: [
            "Diacova AG, Festanstellung für Administration und IT",
            "Beginn nebenberufliche Tätigkeiten als Webentwickler",
            "BFH, Start berufsbegleitendes Informatikstudium",
          ],
        },
        {
          year: 2022,
          archivements: [
            "Vaasan ammattikorkeakoulu, Auslandssemester",
          ],
        },
      ],
    };
  },
};
</script>

<style>
.timeline {
  width: 66%;
  margin: 0 auto;
  position: relative;
  font-size: 1.1em;
}

.timeline h2 {
  padding: 0;
  margin: 0;
  text-align: right;
}

/* strich */
.timeline::before {
  content: "";
  position: absolute;
  width: 2px;
  background-color: var(--white);
  left: calc(50% - 1px);
  height: calc(100% - 11vh);
  top: 5vh;
}

.year {
  display: flex;
  width: 100%;
  margin-bottom: 10vh;
}

.year > div {
  width: 50%;
  padding: 20px;
}

.year .archivement {
  margin-top: 10vh;
}

.year .archivement:first-child{
  margin-top: 13vh;
}

.year .yeartitle,
.year .archivement {
  position: relative;
}

/* jahrespunkte */
.year .yeartitle::before {
  content: "";
  width: 20px;
  height: 20px;
  border: 3px var(--white) solid;
  border-radius: 50%;
  position: absolute;
  right: -13px;
  background-color: var(--primary);
  top: 32px;
}

/* itempunkte */
.year .archivement::before {
  content: "";
  width: 20px;
  height: 20px;
  border: 3px var(--white) solid;
  border-radius: 50%;
  position: absolute;
  left: -32.8px;
  background-color: var(--darkgrey);
}
</style>