<template>
  <div class="text-container">
    <div class="entry">
      <div class="content-text">
        <component v-bind:is="article"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, } from 'vue'
import { useRouter } from 'vue-router'
let testvar = 0;

export default {
  setup(props) {
    try {
      require('./articles/' + props.blogdata + '.vue')
    } catch (error) {
      const router = useRouter()
      router.push({
        name: "404",
      })
    }

    let article
    article = defineAsyncComponent({
      loader: () => import('./articles/' + props.blogdata + '.vue'),
    })

    testvar = article

/*     document.querySelector('meta[name="description"]').content = propsObject.blogdescription;
    document.querySelector('title').innerHTML =  propsObject.blogtitle */

    return {
      article
    }
  },
  props: {
    blogdata: {
      type: String,
      required: true
    }
  },
  components: {
    Article: () => { return testvar },
  },
}
  //TODO: set Description and Title
</script>

<style>

</style>