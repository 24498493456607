<template>
    <div class="blog_list">
        <Videosection v-for="(article, index)  in blogs" :key="index" :title="article.title"
            :text="article.description " :media="'blogarticles/' + article.filename+'.png'"
            :alt="'Bild von' + article.title" link="Blogarticle" linkText="Lesen"
            :linkParams="{blogdata : article.filename}" :left="index % 2 != 0" />
    </div>
</template>

<script>
import blogsJSONFile from '@/assets/bloglist.json'
import Videosection from '../Videosection.vue';
export default {
    components: {
        Videosection
    },
    methods: {
        navigateBlog() {
            JSON.stringify()
        }
    },
    data() {
        return {
            blogs: blogsJSONFile
        }
    }
}
</script>

<style>

</style>